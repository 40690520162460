import { SelectedMapObject } from "@iventis/map-types";
import { ObjectUpdate } from "@iventis/map-engine/src/types/events-store";
import { EventStream } from "@iventis/utilities";

export enum MapDataTableEvent {
    /** Only call once the backend has successfully created the objects */
    MapObjectsCreatedRemotely = "MapObjectsCreatedRemotely",
    /** Only call once the backend has successfully updated the objects */
    MapObjectsUpdatedRemotely = "MapObjectsUpdatedRemotely",
    /** Only call once the backend has successfully deleted the objects */
    MapObjectsDeletedRemotely = "MapObjectsDeletedRemotely",
    /** Call as soon as the update happens locally OR once the backend has successfully updated */
    MapObjectsUpdated = "MapObjectsUpdated",
    MapObjectsSelected = "MapObjectsSelected",
}

export type MapObjectsCreatedRemotelyEvent = {
    type: MapDataTableEvent.MapObjectsCreatedRemotely;
    payload: ObjectUpdate[];
};

export type MapObjectsUpdatedRemotelyEvent = {
    type: MapDataTableEvent.MapObjectsUpdatedRemotely;
};

export type MapObjectsUpdatedEvent = {
    type: MapDataTableEvent.MapObjectsUpdated;
    payload: ObjectUpdate[];
};

export type MapObjectsDeletedRemotelyEvent = {
    type: MapDataTableEvent.MapObjectsDeletedRemotely;
    payload: string[];
};

export type MapObjectsSelectedEvent = {
    type: MapDataTableEvent.MapObjectsSelected;
    payload: SelectedMapObject[];
};

/** An event stream required for keeping the data table in sync with the map (MAP --> DATA TABLE) */
export const mapDataTableEventStream = new EventStream<
    MapObjectsCreatedRemotelyEvent | MapObjectsUpdatedRemotelyEvent | MapObjectsUpdatedEvent | MapObjectsDeletedRemotelyEvent | MapObjectsSelectedEvent
>();
